import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack, Typography, alpha } from "@mui/material";
import type { EditorOptions } from "@tiptap/core";
import { LinkBubbleMenu, RichTextEditor, RichTextEditorRef, TableBubbleMenu, insertImages } from "mui-tiptap";
import { RefObject, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import useExtensions from "../../../hooks/use-extensions";
import useProject from "../panel/use-project";
import EditorMenuControls from "./content-editor-menu-control";
import editorSlice from "./editor.slice";
import { SelectionBubbleMenu } from "./selection-bubble-menu";
import axios from "axios";

function fileListToImageFiles(fileList: FileList): File[] {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || "").toLowerCase();
    return mimeType.startsWith("image/");
  });
}

type Props = {
  editorRef: RefObject<RichTextEditorRef>;
};

export default function ContentEditor(props: Props) {
  const editorState = useSelector((state: RootState) => state.editor);
  const { apiUrl } = useSelector((state: RootState) => state.home);
  const project = useProject(props.editorRef);

  const extensions = useExtensions({
    placeholder: "Add your own content here...",
  });
  const editorOpened = useSelector((state: RootState) => state.editor.editorOpened);
  const defaultContent = useSelector((state: RootState) =>
    editorOpened === "article" ? state.editor.article?.content : state.editor.article?.outline,
  );
  const editorEditable = useSelector((state: RootState) => state.editor.editorEditable);
  const dispatch = useDispatch();

  const handleNewImageFiles = useCallback((files: File[], insertPosition?: number): void => {
    if (!props.editorRef.current?.editor) {
      return;
    }

    Promise.all(
      files.map(async (file) => ({
        src: await uploadImage(apiUrl, file),
        alt: file.name,
      })),
    ).then((attributesForImageFiles) => {
      insertImages({
        images: attributesForImageFiles,
        editor: props.editorRef.current?.editor ?? null,
        position: insertPosition,
      });
    });
  }, []);

  // Allow for dropping images into the editor
  const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = fileListToImageFiles(event.dataTransfer.files);
      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);

        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles],
  );

  // Allow for pasting images
  const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = fileListToImageFiles(event.clipboardData.files);
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      // We return false here to allow the standard paste-handler to run.
      return false;
    },
    [handleNewImageFiles],
  );

  const handleOutlineUpdate = (editor: any) => {
    if (editorState.article?.generating) return;

    dispatch(editorSlice.actions.updateLocalArticleOutline(editor.editor.getHTML()));

    if (editorState.localArticle?.outline !== editorState.article?.outline && !editorState.article?.generating) {
      project.debounceHandleSave({
        id: editorState.article?.id as string,
        outline: editor.editor.getHTML(),
      });
    }
  };

  /*
   * Remove <p> tags from the beginning of <li> elements.
   *
   * This function is necessary because the editor library (TipTap)
   * automatically wraps the content of list items in <p> tags.
   *
   * We don't want <p> tags in the beginning of list items, because in
   * WordPress this results in an extra line break at the beginning of the
   * list item.
   *
   * Ref:
   *  - https://github.com/teamniteo/wordformai/issues/111
   *  - https://github.com/ueberdosis/tiptap/issues/118
   */
  const fixListHtml = (content: string): string => {
    // Create a temporary container to parse and hold the HTML content
    const container = document.createElement("div");
    container.innerHTML = content;

    // Get all <li> elements in the parsed content
    const liElements = container.querySelectorAll("li");

    for (const li of liElements) {
      let firstContentNode: ChildNode | null = null;

      // Find the first non-whitespace, meaningful node
      for (const node of li.childNodes) {
        const isNonWhitespaceText = node.nodeType === Node.TEXT_NODE && node.textContent?.trim() !== "";
        const isElementNode = node.nodeType === Node.ELEMENT_NODE;

        if (isNonWhitespaceText || isElementNode) {
          firstContentNode = node;
          break;
        }
      }

      // If the first meaningful node is a <p>, unwrap it
      if (firstContentNode && firstContentNode.nodeName.toLowerCase() === "p") {
        const pElement = firstContentNode as HTMLParagraphElement;
        // Unwrap the <p> element
        li.innerHTML = li.innerHTML.replace(pElement.outerHTML, pElement.innerHTML);
      }
    }

    // Return the modified HTML as a string
    return container.innerHTML;
  };

  const handleArticleUpdate = (editor: any, forceSave?: boolean) => {
    if (editorState.article?.generating) return;

    const articleContent = fixListHtml(editor.editor.getHTML());

    dispatch(editorSlice.actions.updateLocalArticleContent(articleContent));

    if (
      forceSave ||
      (editorState.localArticle?.content !== editorState.article?.content && !editorState.article?.generating)
    ) {
      project.debounceHandleSave({
        id: editorState.article?.id as string,
        content: articleContent,
      });
    }
  };

  const content = editorState.editorOpened === "outline" ? editorState.article?.outline : editorState.article?.content;
  const cursorStyle =
    editorState.article?.generating && content && content.length > 3
      ? {
          content: '""', // Empty content
          display: "inline-block",
          verticalAlign: "middle", // Align with the middle of the text line
          color: (theme: any) => theme.palette.primary.main,
          borderRight: "4px solid", // This is the cursor, adjust the width as required
          height: "1em", // Match the height of a typical line of text
          animation: "blink 1s infinite",
        }
      : {};

  return (
    <>
      <Box
        sx={{
          width: "100%",
          //Blinking cursor
          "& .ProseMirror > p:last-child": {
            display: "inline-block",
            "&:after": cursorStyle,
          },
          "& .ProseMirror": {
            mx: "10px",
          },
          "& .ProseMirror > :not(p):last-child h1, & .ProseMirror > :not(p):last-child h2, & .ProseMirror > :not(p):last-child h3, & .ProseMirror > :not(p):last-child h4, & .ProseMirror > :not(p):last-child h5":
            {
              display: "inline-block",
              "&:after": cursorStyle,
            },
          display: "inline-block",

          "@keyframes blink": {
            "0%, 49%": { opacity: 1 },
            "50%, 100%": { opacity: 0 },
          },
          // Ensure p has no margin inside tables (like in Tiptap's example CSS https://tiptap.dev/api/nodes/table)
          "& table p": {
            margin: 0,
          },
          // Ensure p has no margin inside task lists (like in Tiptap's example CSS https://tiptap.dev/api/nodes/task-list)
          '& ul[data-type="taskList"] p': {
            margin: 0,
          },
          "& p, & ul, & ol, & table": {
            paddingBottom: "25px",
            fontSize: "18px",
          },
          "& li > p, & blockquote > p, & ul > p": {
            paddingBottom: "5px",
          },
          "& h1, & h2, & h3, & h4, & h5, & h6": {
            paddingBottom: "10px",
          },
          "& h1, & h2, & h3": {
            fontWeight: "700",
            paddingBottom: "10px",
          },
          "& mark": {
            background: (theme) => alpha(theme.palette.primary.main, 0.1),
          },
          "& img": {
            width: "500px",
            my: "25px",
          },
          "& figcaption": {
            fontSize: "5px",
            color: "gray",
          },
        }}
      >
        <RichTextEditor
          ref={props.editorRef}
          extensions={extensions}
          content={defaultContent}
          onUpdate={editorState.editorOpened === "outline" ? handleOutlineUpdate : handleArticleUpdate}
          editable={editorEditable}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={() =>
            editorState.article?.status && (
              <EditorMenuControls editorOpened={editorState.editorOpened} editorRef={props.editorRef} />
            )
          }
          RichTextFieldProps={{
            variant: "standard",
            footer: (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                  width: "100%",
                }}
              >
                <Stack alignItems="center" direction="row" spacing={2}>
                  <LoadingButton
                    loading={editorState.editorSaving}
                    disabled={
                      editorState.localArticle?.content === editorState.article?.content ||
                      editorState.article?.generating
                    }
                    variant="contained"
                    size="small"
                    onClick={() => {
                      editorState.editorOpened === "outline"
                        ? project.handleSave({
                            id: editorState.article?.id as string,
                            outline: editorState.localArticle?.outline as string,
                          })
                        : project.handleSave({
                            id: editorState.article?.id as string,
                            content: editorState.localArticle?.content as string,
                          });
                    }}
                  >
                    Save
                  </LoadingButton>
                  <Typography variant="caption" color="text.secondary">
                    {!editorState.editorSaving ? "All Changes Saved" : "Saving..."}
                  </Typography>
                </Stack>
                {!editorState.article?.generating && (
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Typography variant="caption" color="text.secondary">
                      {editorState.localArticle?.content?.split(" ").length || 0} words,{" "}
                      {editorState.article?.content?.length || 0} characters
                    </Typography>
                  </Stack>
                )}
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              {editorState.editorOpened === "article" && editorState.editorEditable && (
                <>
                  <LinkBubbleMenu />
                  <TableBubbleMenu />
                  <SelectionBubbleMenu
                    editorRef={props.editorRef}
                    onUpdate={(editor) => {
                      handleArticleUpdate(editor, true);
                    }}
                  />
                </>
              )}
            </>
          )}
        </RichTextEditor>
      </Box>
    </>
  );
}

async function uploadImage(apiUrl: string, file: File): Promise<string> {
  const formData = new FormData();
  formData.append("image", file);
  if (file.size > 2 * 1024 * 1024) {
    alert("Image size must be less than 2MB");
    return "";
  }
  try {
    const response = await axios.post(`${apiUrl}/article/upload-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.result.url;
  } catch (error) {
    alert("Failed to upload image! Please use image upload button instead.");
    return "";
  }
}
