import { Box, Container, Link, Typography } from "@mui/material";

const BUSINESS_NAME = "SCPS Technologies LLC";
const items = [
  {
    label: "About Us",
    href: "https://wordform.ai",
  },
  {
    label: "Terms",
    href: "https://wordform.ai/",
  },
];

export const Footer = () => (
  <footer>
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        py: 3,
        "& a": {
          mt: {
            xs: 1,
            sm: 0,
          },
          "&:not(:last-child)": {
            mr: {
              xs: 0,
              sm: 5,
            },
          },
        },
      }}
    >
      <Typography color="text.secondary" variant="caption">
        © {new Date().getFullYear()} {BUSINESS_NAME}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      {items.map((link) => (
        <Link
          color="text.secondary"
          href={link.href}
          key={link.label}
          target="_blank"
          underline="none"
          variant="body2"
        >
          {link.label}
        </Link>
      ))}
    </Container>
  </footer>
);
