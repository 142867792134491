import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../core/hooks/useStore";
import { RootState } from "../../core/store/store";
import integrationsSlice from "./integrations.slice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import wpAppPasswordScreenshot from "./wp-app-password.webp";

interface ScreenshotDialogProps {
  onClose: () => void;
  isOpen: boolean;
  screenshotSrc: string;
  screenshotAlt: string;
}

const ScreenshotDialog: React.FC<ScreenshotDialogProps> = ({ onClose, isOpen, screenshotSrc, screenshotAlt }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth sx={{ overflow: "hidden" }}>
      <img src={screenshotSrc} alt={screenshotAlt} style={{ width: "100%" }} />
    </Dialog>
  );
};

type CreateIntegrationData = {
  domain: string;
  username: string;
  pass: string;
};

type Props = {
  open: boolean;
  onClose: any;
};

export const CreateIntegrationDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSelector((state: RootState) => state.home);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    formState,
  } = useForm<CreateIntegrationData>({
    mode: "onChange",
  });

  const { open = false, onClose, ...other } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [screenshotOpen, setScreenshotOpen] = useState<boolean>(false);

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const data = { page: 1, pageSize: 999 };
      const response: any = await axios.post(`${apiUrl}/integration/list`, data);
      const result = response.data.result;
      dispatch(integrationsSlice.actions.setIntegrations(result.integrations));
      dispatch(integrationsSlice.actions.setIntegrationsCount(result.count));
      dispatch(integrationsSlice.actions.setPage(1));
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error listing integrations...");
      }
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<CreateIntegrationData> = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/integration/create`, data);
      toast.success("Successfully created integration!");
      await getIntegrations();
      reset();
      props.onClose();
    } catch (e: any) {
      if (e.response?.data?.error?.message) {
        toast.error("Error: " + e.response.data.error.message);
      } else if (e.request) {
        toast.dismiss();
        toast.error("Network error occurred...");
      } else {
        toast.error("Error creating integration");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <ScreenshotDialog
        onClose={() => setScreenshotOpen(false)}
        isOpen={screenshotOpen}
        screenshotSrc={wpAppPasswordScreenshot}
        screenshotAlt={"WordPress App Password Screenshot"}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        open={open}
        TransitionProps={{
          onExited: () => reset(),
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add WordPress Blog Integration</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <DialogContentText>
                WordPress blogs require an application password, which is not the same as your account password.
              </DialogContentText>

              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="instructions-content"
                    id="instructions-header"
                    sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}
                  >
                    <Typography>Instructions</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 4 }}>
                    <Typography>
                      Log into your WordPress:
                      <ol>
                        <li style={{ paddingBottom: "12px" }}>
                          Go to the <strong>Users menu</strong> and open the user you want to be using for posting.
                          Scroll to the bottom to <strong>Application Passwords</strong>.
                        </li>
                        <li style={{ paddingBottom: "12px" }}>
                          Under New Application Password Name, enter <i>Wordform Integration</i>. Click the button{" "}
                          <strong>Add New Application Password</strong>.
                        </li>
                        <li style={{ paddingBottom: "12px" }}>
                          <strong>Copy</strong> the <strong>application password</strong> and add it below.
                        </li>
                        <li style={{ paddingBottom: "12px" }}>
                          For Easy Blog Networks users: make sure you enable REST API on the blog page.
                        </li>
                      </ol>
                      <Typography>
                        Not sure if you are in the right place?{" "}
                        <Link component="button" variant="body1" onClick={() => setScreenshotOpen(true)}>
                          Open the screenshot
                        </Link>
                      </Typography>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <Stack spacing={2} sx={{ paddingTop: "24px" }}>
                <TextField
                  fullWidth
                  label="Domain"
                  disabled={loading}
                  {...register("domain", { required: true, minLength: 3 })}
                />
                <TextField
                  fullWidth
                  label="Username"
                  disabled={loading}
                  {...register("username", { required: true, minLength: 2 })}
                />
                <TextField
                  fullWidth
                  label="Application Password"
                  type="password"
                  disabled={loading}
                  {...register("pass", { required: true, minLength: 2 })}
                />
                {!formState.isValid && (
                  <FormHelperText error>Please enter your correct site credentials!</FormHelperText>
                )}
              </Stack>
              <FormHelperText error sx={{ mt: 2 }}></FormHelperText>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit" disabled={!formState.isValid || loading}>
              Create Integration
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
