import MenuIcon from "@mui/icons-material/Menu";
import { Box, Container, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import Logo from "../../logo/logo";
import AccountPopover from "./account-popover";

const TOP_NAV_HEIGHT = 64;

export const TopNav = (props: {
  onNavOpen: (newState: boolean) => void;
  isNavOpen: boolean;
}) => {
  const { onNavOpen } = props;
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const authState = useSelector((state: RootState) => state.auth);

  return (
    <Box
      component="header"
      sx={{
        backgroundColor: "neutral.900",
        color: "common.white",
        position: "fixed",
        width: "100%",
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container maxWidth={"xl"} sx={{}}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 3,
            paddingLeft: mdDown ? 0 : "1rem",
          }}
        >
          <Stack alignItems="center" direction="row" spacing={3}>
            {mdDown && (
              <Box
                sx={{
                  display: "inline-flex",
                  height: 28,
                  width: 28,
                }}
              >
                <IconButton color="inherit" onClick={() => onNavOpen(!props.isNavOpen)}>
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{
                display: "inline-flex",
                height: 28,
                width: 28,
              }}
            >
              <Logo light />
            </Box>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <AccountPopover
              name={authState.user?.firstName + " " + authState.user?.lastName}
              email={authState.user?.email}
              plan={"Plan: " + (authState.plan?.selected?.name || "No Subscription")}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default TopNav;
