import HandymanIcon from "@mui/icons-material/Handyman";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Divider, Drawer, SvgIcon, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import { Scrollbar } from "../../components/scrollbar";
import { SideNavItem } from "./side-nav-item";
import UsageBar from "./usage-bar";

const SIDE_NAV_WIDTH = 270;
const SIDE_NAV_COLLAPSED_WIDTH = 73; // icon size + padding + border right
const TOP_NAV_HEIGHT = 64;

export const SideNav = (props: {
  active: string;
  sideOpen: boolean;
}) => {
  const { active, sideOpen } = props;
  const [hovered, setHovered] = useState<boolean>(false);
  const collapse = false;
  const authState = useSelector((state: RootState) => state.auth);
  const creditsUsed: number = authState.credits?.used || 0;
  const creditsAvailable: number = authState.credits?.available || 0;
  const imagesUsed: number = authState.imageLimit?.used || 0;
  const imagesAvailable: number = authState.imageLimit?.available || 0;
  //Determine number of tools active
  const allToolKeys = authState.tools ? Object.keys(authState.tools) : [];
  const activeTools = allToolKeys.filter((key) => authState.tools && authState.tools[key] !== null);

  //Show projects if they have a Wordform plan
  const showProjects = authState.plan?.selected;

  const items = [
    {
      title: "Projects",
      path: "/projects",
      key: "projects",
      icon: <SvgIcon>{showProjects ? <HomeIcon /> : <LockIcon />}</SvgIcon>,
    },
    {
      title: "Integrations",
      path: "/integrations",
      key: "integrations",
      icon: (
        <SvgIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 30 30"
            fill="currentColor"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M24.084,10.843 c0.003,0.096,0.018,0.178,0.018,0.278c0,1.043-0.196,2.217-0.783,3.687l-2.987,8.637c-0.634,0.402-1.315,0.732-2.034,0.984 l-3.116-8.531l-2.991,8.692c-0.64-0.188-1.253-0.437-1.833-0.742L5.747,11.221c0.271-0.661,0.609-1.286,1.008-1.868 c0.102,0.002,0.217,0.005,0.307,0.005c1.076,0,2.743-0.131,2.743-0.131c0.556-0.031,0.62,0.783,0.066,0.849 c0,0-0.558,0.065-1.178,0.098l3.748,11.151l2.252-6.757l-1.602-4.396c-0.556-0.031-1.08-0.096-1.08-0.096 c-0.556-0.033-0.489-0.882,0.065-0.849c0,0,1.7,0.131,2.71,0.131c1.076,0,2.743-0.131,2.743-0.131 c0.556-0.031,0.62,0.783,0.066,0.849c0,0-0.559,0.065-1.178,0.098l3.72,11.066l1.028-3.434c0.522-1.337,0.783-2.444,0.783-3.327 c0-1.272-0.456-2.153-0.849-2.838c-0.521-0.849-1.012-1.566-1.012-2.415c0-0.849,0.58-1.64,1.431-1.794 C22.602,8.368,23.48,9.529,24.084,10.843z"></path>
          </svg>
        </SvgIcon>
      ),
    },
    {
      title: "Tools",
      path: "/tools",
      key: "tools",
      icon: <SvgIcon>{allToolKeys.length === activeTools.length ? <HandymanIcon /> : <LockIcon />}</SvgIcon>,
    },
    {
      title: "Training",
      path: "/training",
      key: "training",
      icon: (
        <SvgIcon>
          <LibraryBooksIcon />
        </SvgIcon>
      ),
    },
    // {
    //   title: "Activity",
    //   path: "/activity",
    //   key: "activity",
    //   icon: (
    //     <SvgIcon>
    //       <ReceiptLogIcon />
    //     </SvgIcon>
    //   ),
    // },
  ];
  return (
    <Drawer
      open={props.sideOpen}
      variant="permanent"
      PaperProps={{
        onMouseEnter: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
        sx: {
          backgroundColor: "background.default",
          height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
          overflowX: "hidden",
          top: TOP_NAV_HEIGHT,
          transition: "width 250ms ease-in-out",
          width: collapse ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH,
          zIndex: (theme) => theme.zIndex.appBar - 100,
        },
      }}
    >
      <Scrollbar
        sx={{
          height: "100%",
          overflowX: "hidden",
          "& .simplebar-content": {
            height: "100%",
          },
        }}
      >
        <Box
          component="nav"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
          }}
        >
          <Box
            component="ul"
            sx={{
              flexGrow: 1,
              listStyle: "none",
              m: 0,
              p: 0,
            }}
          >
            {items.map((item, k) => {
              return (
                <div key={k}>
                  <SideNavItem
                    active={active === item.key}
                    collapse={collapse}
                    depth={0}
                    external={false}
                    icon={item.icon}
                    key={item.key}
                    path={item.path}
                    title={item.title}
                  />
                </div>
              );
            })}
          </Box>
          <Divider />
          <Box sx={{ pt: 1 }}>
            <UsageBar label="Article Credits Used" used={creditsUsed} available={creditsAvailable} />

            {imagesAvailable > 0 && (
              <UsageBar label="Image Credits Used" used={imagesUsed} available={imagesAvailable} />
            )}

            <Divider sx={{ pt: 1 }} />
            <Box sx={{ pt: 0.5, display: "flex", alignItems: "left", flexDirection: "column" }}>
              <Typography variant="body2" color="text.secondary" fontWeight="bold">
                {authState.plan?.selected ? authState.plan?.selected?.name : "No Subscription"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};
