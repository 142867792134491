import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import DashboardLayout from "../../common/layouts/dashboard";
import { useAppDispatch } from "../../core/hooks/useStore";
import { homeSlice } from "../home";

const Training = () => {
  const dispatch = useAppDispatch();
  const training = [
    {
      name: "Basic Training",
      k: 0,
      html: `<div style="max-width: 960px;">
<h2>Beginner Training</h2>
<p>The beginner training will teach you the basic concepts of Wordform AI and how to start using it to post content to your Wordpress website</p>
<h4>1. Welcome to the Beginner Training!</h4>
<div style="padding: 56.2% 0 0 0; position: relative;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="1. welcome video - wordform ai training" src="https://player.vimeo.com/video/940819037?h=101654244c&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameborder="0"></iframe></div>
<p>In this video, Brandon walks you through the basics of the Wordform AI platform and what we will cover in this training.</p>
<h4>2. Niche Selection</h4>
<div style="padding: 56.2% 0 0 0; position: relative;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="niche selection" src="https://player.vimeo.com/video/940819684?h=c57334be63&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameborder="0"></iframe></div>
<p>In this video, Brandon explains what makes a good niche and how to use AI to quickly find profitable niches.</p>
<ul>
<li>Niche Pig: <a href="https://wordform.ai/niche-generator/">https://wordform.ai/niche-generator/</a></li>
<li>Topic Pig: <a href="https://wordform.ai/keyword-generator/">https://wordform.ai/keyword-generator/</a></li>
<li>Headline Pig: <a href="https://wordform.ai/headline-generator/">https://wordform.ai/headline-generator/</a></li>
</ul>
<h4>3. Content Creation (Part 1)</h4>
<div style="padding: 56.2% 0 0 0; position: relative;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="content creation part 1" src="https://player.vimeo.com/video/940820597?h=bf1efff4ea&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameborder="0"></iframe></div>
<p>In this video, Brandon shows how to get started creating content in Wordform AI (Part 1).</p>
<h4>4. Content Creation (Part 2)</h4>
<div style="padding: 56.2% 0 0 0; position: relative;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="Content creation part 2" src="https://player.vimeo.com/video/940820662?h=fab6c68e36&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameborder="0"></iframe></div>
<p>In this video, Brandon shows how to get started creating content in Wordform AI (Part 2).</p>
<h4>5. Content Creation (Part 3)</h4>
<div style="padding: 56.2% 0 0 0; position: relative;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="content creation part 3" src="https://player.vimeo.com/video/940820943?h=f761a2a013&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" frameborder="0"></iframe></div>
<p>In this video, Brandon shows how to get started creating content in Wordform AI (Part 3).</p>
<h4>6. Google Adsense</h4>
<div style="padding:56.2% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/940821023?h=09e4409177&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="google adsense"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script><p>In this video, Brandon shows you how to monetize your site with Google Adsense.</p>
<ul>
<li>Google Adsense: <a href="https://adsense.google.com/start/">https://adsense.google.com/start/</a></li>
</ul>
<h4>7. Website Flipping</h4>
<div style="padding:56.2% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/940821105?h=0d8e432436&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="website flipping"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script><p>In this video, Brandon shows you how to flip websites for a profit on sites like Flippa</p>
<ul>
<li>Flippa: <a href="https://flippa.com/">https://flippa.com/</a></li>
</ul>
<h4>8. Affiliate Marketing</h4>
<div style="padding:56.2% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/940821203?h=95bda41dac&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="affiliate marketing"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script><p>In this video, Brandon shows how to use Wordform AI for affiliate marketing</p>
<ul>
<li>Clickbank: <a href="https://www.clickbank.com/">https://www.clickbank.com/</a></li>
</ul>
<h4>9. Tracking Your Results</h4>
<div style="padding:56.2% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/940821386?h=4f5bb04811&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="tracking your results"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script><p>In this video, Brandon shows you how to track your results over time.</p>
<h4>10. Conclusion</h4>
<div style="padding:56% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/940821557?h=8093d1d66d&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="conclusion"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script><p>In this video, Brandon concludes the beginner training for Wordform AI.</p>
<p>Once you've followed the beginner training, make sure to sign up for the Wordform AI live training at <a href="https://wordform.ai">https://wordform.ai</a>!</p>
</div>`,
    },
    {
      name: "Invisible Influencer",
      k: 1,
      html: `<h2>Invisible Influencer</h2>

<h4>Training Video 1 - Chris Munch</h4>
<p><div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1021629105?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Wordform - Social Advertorial System - Lesson 1"></iframe></div></p>
<p><br />Spreadsheet: <a href="https://docs.google.com/spreadsheets/d/1858HdKKYviubUSoZBxZwJB76CyBQ15zIHiPwn4kz8yo/edit?usp=sharing">Affiliate Programs</a></p>
<p>Affiliate Application: <a href="https://docs.google.com/document/d/1D0gSERrtglM3qYS-c5oBKaQ_LUlN2rrRiFgjcbnEhE0/edit?usp=sharing">Template</a></p>
<p>&nbsp;</p>
<div>
<h4>Training Video 2 - Stewart Vickers</h4>
<p><div style="padding:42.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1021767101?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Wordform - Social Advertorial System - Lesson 2"></iframe></div></p>
</div>

<h4>Training Video 3 - Agency</h4>
<p><div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1044256223?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Training Video 3 - Agency"></iframe></div></p><script src="https://player.vimeo.com/api/player.js"></script>
<p><br /><a href="https://go.wordform.ai/blogcontentplanning">Blog Content Planning Manual</a></p>
<p><a href="https://go.wordform.ai/questionstoask">Questions to ask clients</a></p>
<p><a href="https://go.wordform.ai/dfyagencysalespage">DFY Sales Page</a></p>
`,
    },
    {
      name: "Bonuses",
      k: 1,
      html: `<h2>Bonuses</h2>
<p>Below are the bonuses that were included with your free trial:</p>
<p><strong>Bonus #1: Over-the-Shoulder Training</strong></p>
<p>This is available within Training &gt; Beginner Training.</p>
<p><strong>Bonus #2: Live Training</strong></p>
<p>Previous live trainings are available on <a href="https://wordform.ai/replay" rel="noopener noreferrer nofollow" target="_blank">https://wordform.ai/replay</a>. For new ones, keep an eye on your email..</p>
<p><strong>Bonus #3: Five Website Templates</strong></p>
<p>Here are the five website templates that are ready to install.</p>
<table style="height: 53px; width: 541px;">
<tbody>
<tr>
<td style="width: 157px;">Template Image</td>
<td style="width: 174.289px;">Template Name</td>
<td style="width: 119.711px;">Download Link</td>
</tr>
<tr>
<td style="width: 157px;">&nbsp;<img src="https://moneysitefactory.com/wp-content/uploads/2021/08/YRj7AUP.png" alt="" width="190" height="200" /></td>
<td style="width: 174.289px;">&nbsp;Home Decor Template&nbsp;</td>
<td style="width: 119.711px;"><a href="https://drive.google.com/uc?export=download&amp;id=1F0I-QJVQQarC_XXWP7bKVk5UtDsdyelK">https://drive.google.com/uc?export=download&amp;id=1F0I-QJVQQarC_XXWP7bKVk5UtDsdyelK</a></td>
</tr>
<tr>
<td style="width: 157px;"><img src="https://moneysitefactory.com/wp-content/uploads/2021/08/egixD2G.png" width="192" height="197" /></td>
<td style="width: 174.289px;">Business Template&nbsp;</td>
<td style="width: 119.711px;"><a href="https://drive.google.com/uc?export=download&amp;id=1qvCP-QX-Nc8l7FP2YS9OTFak04SimFyD">https://drive.google.com/uc?export=download&amp;id=1qvCP-QX-Nc8l7FP2YS9OTFak04SimFyD&nbsp;</a></td>
</tr>
<tr>
<td style="width: 157px;">&nbsp;<img src="https://moneysitefactory.com/wp-content/uploads/2021/08/zXuPQVv.png" width="175" height="180" /></td>
<td style="width: 174.289px;">Fashion Template</td>
<td style="width: 119.711px;"><a href="https://drive.google.com/uc?export=download&amp;id=1-GZ2Es9XUBUhtaVnEdB9OA9PAWrwMp9U">https://drive.google.com/uc?export=download&amp;id=1-GZ2Es9XUBUhtaVnEdB9OA9PAWrwMp9U&nbsp;</a></td>
</tr>
<tr>
<td style="width: 157px;">&nbsp;<img src="https://moneysitefactory.com/wp-content/uploads/2021/08/quBS5Us.png" width="186" height="181" /></td>
<td style="width: 174.289px;">Investing Template&nbsp;</td>
<td style="width: 119.711px;"><a href="https://drive.google.com/uc?export=download&amp;id=1nccRSQo3qP7Obi6d1-bLSdisYHH6Ju7g">https://drive.google.com/uc?export=download&amp;id=1nccRSQo3qP7Obi6d1-bLSdisYHH6Ju7g&nbsp;</a></td>
</tr>
<tr>
<td style="width: 157px;">&nbsp;<img src="https://moneysitefactory.com/wp-content/uploads/2021/08/NfqJ915.png" width="189" height="184" /></td>
<td style="width: 174.289px;">Pets Template</td>
<td style="width: 119.711px;"><a href="https://drive.google.com/uc?export=download&amp;id=1autOUQgM7fqUhi7a61pjP2guvybXQtVb">https://drive.google.com/uc?export=download&amp;id=1autOUQgM7fqUhi7a61pjP2guvybXQtVb&nbsp;</a></td>
</tr>
</tbody>
</table>
<p><em>Here is a video showing you how to use them:</em></p>
<p><iframe src="https://player.vimeo.com/video/585642735" width="400px" height="200px" frameborder="0"></iframe></p>
<p><em>Default Credentials:</em></p>
<p>Username: admin</p>
<p>Password: Vk1x@q!GS0ytHiWn40KFWDJy</p>
<p>(This bonus is from a course called Money Site Factory but you can still use the information above to download all of the templates.)</p>
<p>Note: These templates have pre-installed demo content on them but you can delete all these posts and start auto-posting content from Wordform AI instead!</p>
<p><strong>Bonus #4: Monetization Blueprint</strong></p>
<p>This is a step-by-step blueprint for turning clicks into cash!</p>
<p><a href="https://docs.google.com/document/d/1kFwlXWEPTsNa3g8Q_hSpK1u7DG3orOOU/edit?usp=share_link&amp;ouid=108559562515642288562&amp;rtpof=true&amp;sd=true">You can download the PDF by going here!&nbsp;</a></p>`,
    },
  ];
  useEffect(() => {
    dispatch(homeSlice.actions.setActiveMenu("training"));
  }, [dispatch]);

  const [tab, setTab] = useState<string>("0");

  useEffect(() => {
    document.title = "Wordfrom AI - Training";
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <DashboardLayout active={"training"} name="Training">
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {training.map((t: { name: string; html: string }, k: number) => {
              return <Tab label={t.name} value={k.toString()} />;
            })}
          </TabList>
        </Box>
        {training.map((t: { name: string; html: string }, k: number) => {
          return (
            <TabPanel value={k.toString()}>
              <div dangerouslySetInnerHTML={{ __html: t.html }}></div>
            </TabPanel>
          );
        })}
      </TabContext>
    </DashboardLayout>
  );
};

export default Training;
